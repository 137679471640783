import React, { Fragment, useState, useEffect } from 'react'
import Charts from './charts'

const isEmpty = (a) => {
  return a === undefined || a === null
}

const sortMonths = (a, b) => {
  if (a > b) {
    return -1
  } else {
    return 1
  }
}

const StatsGrid = (props) => {
  try {
    const sel = isEmpty(props.userFilter) ? 'total' : props.userFilter
    const hist = props.cumulative[sel]
    const yyyymms = Object.keys(hist)
    return (
      <>
        {yyyymms.sort(sortMonths).map((yyyymm, ih) => {
          const odd = ih % 2 === 0 // 0 is the first row, i.e. odd
          const oddbg = odd ? ' bg-dusty-grey-light' : ''
          const onehist = hist[yyyymm]
          return (
            <Fragment key={ih}>
              <div className={'gcs1 gce2 pa2' + oddbg}>{yyyymm}</div>
              <div className={'gcs2 gce3 pa2' + oddbg}>{onehist.created}</div>
              <div className={'gcs3 gce4 pa2' + oddbg}>{onehist.reminder}</div>
              <div className={'gcs4 gce5 pa2' + oddbg}>
                {onehist.acknowledged}
              </div>
              <div className={'gcs5 gce6 pa2' + oddbg}>
                {onehist.fbreceived}
              </div>
            </Fragment>
          )
        })}
      </>
    )
  } catch (error) {
    return <>Empty</>
  }
}

export default function Statistics(props) {
  const [histKeys, setHistKeys] = useState([])
  const [cumulative, setCumulative] = useState([])

  useEffect(() => {
    const users = Object.keys(props.history)

    setHistKeys(Object.keys(props.subUsers))

    const o = {}
    const total = {}

    for (let index = 0; index < users.length; index++) {
      const user = users[index]
      const uapps = props.history[user]
      const u_mths = Object.keys(uapps)
      for (let im = 0; im < u_mths.length; im++) {
        const yyyymm = u_mths[im]
        if (isEmpty(total[yyyymm])) {
          total[yyyymm] = {
            acknowledged: 0,
            created: 0,
            deleted: 0,
            fbreceived: 0,
            reminder: 0,
            reminderemail: 0,
            remindersms: 0,
            maxreminder: 0,
          }
        }
        total[yyyymm].acknowledged += uapps[yyyymm].acknowledged
        total[yyyymm].created += uapps[yyyymm].created
        total[yyyymm].deleted += uapps[yyyymm].deleted
        total[yyyymm].fbreceived += uapps[yyyymm].fbreceived
        total[yyyymm].reminder += uapps[yyyymm].reminder
        total[yyyymm].reminderemail += uapps[yyyymm].reminderemail
        total[yyyymm].remindersms += uapps[yyyymm].remindersms
        total[yyyymm].maxreminder += uapps[yyyymm].maxreminder
      }
      o[user] = uapps
    }

    o['total'] = total
    setCumulative(o)
  }, [props.history, props.subUsers])

  return (
    <>
      <div className="w-100 h-100">
        <div className="grid feedback-grid sticky misty-blue-mid top-feedback bg-white pv2 nt4 h4 z-1 content-end">
          <div className="gcs1 gce2 ph2 self-end">Month</div>
          <div className="gcs2 gce3 ph2 self-end">Created</div>
          <div className="gcs3 gce4 ph2 self-end">Reminders</div>
          <div className="gcs4 gce5 ph2 self-end">Confirmations</div>
          <div className="gcs5 gce6 ph2 self-end">Feedbacks</div>
        </div>
        {isEmpty(cumulative) || isEmpty(histKeys) ? null : (
          <div className="grid feedback-grid">
            <StatsGrid
              histKeys={histKeys}
              userFilter={props.userFilter}
              cumulative={cumulative}
            />
          </div>
        )}
        {histKeys.length === 0 ? null : (
          <Charts
            history={cumulative}
            historyKeys={histKeys}
            userFilter={props.userFilter}
          />
        )}
      </div>
    </>
  )
}
