import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const isEmpty = (a) => {
  return a === null || a === undefined || a === ''
}

const orderUser = (a, b) => {
  if (a.label > b.label) {
    return 1
  } else {
    return -1
  }
}

export default function UserSelect(props) {
  const [userList, setUserList] = useState([])

  useEffect(() => {
    const su = props.subusers.map((s) => {
      const uid = Object.keys(s)[0]
      if (isEmpty(s[uid])) {
        return {
          value: '',
          label: 'empty'
        }
      } else {
        return {
          value: s[uid],
          label: `${s[uid].short} (${s[uid].details})`,
        }
      }
    })

    setUserList(
      su.filter(s => s.label !== 'empty').sort(orderUser)
    )
  }, [props.subusers])

  const pickBranch = (b) => {
    // console.log(b)
    props.branchSelect(b)
  }

  return (
    <form className="w-100 sticky top-feedback z-2 pt4">
      <Select
        placeholder="Select branch..."
        options={userList}
        onChange={pickBranch}
        isClearable={true}
      />
    </form>
  )
}
