import React, { useState, useEffect } from 'react'

import SignIn from './components/signIn'
import Statistics from './components/statistics'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { firebaseConfig } from './firebase/firebase'
import UserSelect from './components/userSelect'

const isEmpty = (a) => {
  return a === null || a === undefined
}

const App = () => {
  const [fbLoaded, setFbLoaded] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState({})
  const [user, setUser] = useState({})
  const [subusers, setSubusers] = useState({})
  const [uFired, setUFired] = useState(false)
  const [watchedUsers, setWatchedUsers] = useState([])
  const [userChooser, setUserChooser] = useState(null)

  useEffect(() => {
    console.log('loading fb')
    if (!fbLoaded) {
      console.log('init fb')
      firebase.initializeApp(firebaseConfig)
      setFbLoaded(true)
    }
    if (fbLoaded) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(user)
        } else {
          setUser(null)
        }
      })
    }
  }, [fbLoaded])

  useEffect(() => {
    const loadSubuser = (uid) => {
      return new Promise((res, rej) => {
        if (uid.substr(0, 6) === '__api_') {
          firebase
          .database()
          .ref(`users/${uid}`)
          .once('value')
          .then((usnapshot) => {
            if (
              watchedUsers.findIndex((u) => u === uid) === -1
            ) {
              const wu = JSON.parse(JSON.stringify(watchedUsers))
              wu.push(uid)
              setWatchedUsers(wu)
              firebase
                .database()
                .ref(`stats/${uid}`)
                .on('child_added', (data) => {
                  postHistory(data, uid)
                })
              firebase
                .database()
                .ref(`stats/${uid}`)
                .on('child_changed', (data) => {
                  postHistory(data, uid)
                })
            }
            res({ [uid]: usnapshot.val() })
          })
          .catch((err) => {
            console.log(err)
            rej(null)
          })
        } else {
          // only reporting on api users
          res({empty: true})
        }
        
      })
    }

    if (
      user !== null &&
      !isEmpty(user.uid) &&
      uFired === false
    ) {
      setUFired(true)
      // load all api subusers
      firebase
        .database()
        .ref(`values`)
        .once('value')
        .then((snapshot) => {
          const subuserlist = snapshot.val()
          const sukeys = Object.keys(subuserlist)
          Promise.all(sukeys.map(loadSubuser)).then((s) => {
            setSubusers(s.filter(s => s.empty !== true))
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [user, uFired, subusers, watchedUsers])

  // useEffect(() => {
  //   console.log(subusers)
  // }, [subusers])


  // useEffect(() => {
  //   console.log(userChooser)
  // }, [userChooser])


  const loadSubuser = (su) => {
    try {
      setUserChooser(su.value.uid)  
    } catch (error) {
      setUserChooser(null)
    }
  }

  /**
   * Interface for storing the particular user's history
   */
  const postHistory = (data, uid) => {
    // console.log(data.key, data.val())
    const d = data.val()

    const yyyymm = data.key

    const onehist = {
      acknowledged: isEmpty(d.acknowledged) ? 0 : parseInt(d.acknowledged),
      created: isEmpty(d.created) ? 0 : parseInt(d.created),
      deleted: isEmpty(d.deleted) ? 0 : parseInt(d.deleted),
      fbreceived: isEmpty(d.fbreceived) ? 0 : parseInt(d.fbreceived),
      reminder: isEmpty(d.reminder) ? 0 : parseInt(d.reminder),
      reminderemail: isEmpty(d.reminderemail) ? 0 : parseInt(d.reminderemail),
      remindersms: isEmpty(d.remindersms) ? 0 : parseInt(d.remindersms),
    }
    onehist['maxreminder'] = Math.max(onehist['reminderemail'], onehist['remindersms'])

    setHistory((prevHistory) => {
      const uhist = prevHistory[uid] || {}
      uhist[yyyymm] = onehist
      return ({
      ...prevHistory,
      [uid]: uhist,
    })})
  }

  /**
   * Signing in with password & email
   */
  const signIn = (form) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(form.email, form.password)
      .then((si) => {
        console.log('signed in')
      })
      .catch((err) => {
        console.log(err)
        alert('Incorrect credentials, not able to sign in')
      })
  }

  /**
   * Default workflow is a random long pw is generated
   * So new users would reset their pw on the first login
   */
  const resetPw = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((si) => {
        alert('Password reset email sent')
      })
      .catch((err) => {
        if (err.code === 'auth/user-not-found') {
          alert('No such email exists')
        } else {
          alert('Could not reset password')
        }
      })
  }

  return (
    <div className="avenir w-100 h-100 rw-grid grid smallminheight smallminheight-ns">
      <div className="gcs1 gce2 grs1 gre2 pa2 bg-misty-blue-mid white shadow-2 fixed top-0 right-0 left-0 z-5">
        <div className="mw7 center grid header-grid">
          <div className="gcs1 gce2 grs1 gre2">RemindWorks</div>
          {user === null ? null : (
            <div
              className="gcs2 gce3 grs1 gre2 pointer dim tr"
              onClick={(e) => {
                firebase.auth().signOut()
              }}
            >
              Sign Out
            </div>
          )}
        </div>
      </div>
      <div
        className={
          'gcs1 gce2 grs2 gre3 center w-100 ' + (user === null ? 'mw7' : 'mw7')
        }
      >
        {user === null ? (
          <SignIn
            submitForm={(form) => signIn(form)}
            resetPw={(email) => resetPw(email)}
          />
        ) : (
          <div className="grid gafr">
            {JSON.stringify(subusers) === '{}' ? null : (
              <UserSelect
                subusers={subusers}
                branchSelect={(su) => {
                  loadSubuser(su)
                }}
              />
            )}
            <Statistics history={history} userFilter={userChooser} subUsers={subusers} />
          </div>
        )}
      </div>
    </div>
  )
}

export default App
