import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'

const sortMonths = (a, b) => {
  if (a > b) {
    return -1
  } else {
    return 1
  }
}

export default function Charts(props) {
  const [chart, setChart] = useState({
    chart: {
      type: 'area',
      //   height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    // colors: ['#00ff00', '#00e600', '#00cc00', '#009900', '#006600', '#003300'],
    // plotOptions: {
    //   bar: {
    //     horizontal: true,
    //   },
    // },
    // stroke: {
    //   width: 1,
    //   colors: ['#fff'],
    // },
    title: {
      text: 'Rating Distribution',
    },
    // xaxis: {
    //   categories: [],
    // },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return val + ' ratings'
    //     },
    //   },
    // },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  })

  const [series, setSeries] = useState([])

  useEffect(() => {
    const series = {
      created: 0,
      reminder: 0,
      acknowledged: 0,
      fbreceived: 0,
    }

    const labels = {
      created: 'Created',
      reminder: 'Reminders',
      acknowledged: 'Confirmations',
      fbreceived: 'Feedbacks',
    }
    const sel = props.userFilter === null ? 'total' : props.userFilter
    const hist = props.history[sel] || {}

    const monthKeys = Object.keys(hist).sort(sortMonths)

    const percentages = Object.keys(series).map((k) => ({
      name: `${labels[k]}`,
    }))
    for (let index = 0; index < Object.keys(series).length; index++) {
      const serie = Object.keys(series)[index]
      percentages[index].data = monthKeys.map((m) => {
        return hist[m][serie]
      })
    }
    setSeries(percentages)

    setChart((prevchart) => ({
      ...prevchart,
      xaxis: {
        categories: monthKeys,
      },
    }))
  }, [props.history, props.historyKeys, props.userFilter])

  return (
    <>
      <div className="pv4">Chart:</div>
      <div className="w-100">
        {series.length === 0 ? (
          'No rating feedback yet received, charts will display as soon as customers start to leave ratings'
        ) : (
          <Chart options={chart} series={series} type="area" />
        )}
      </div>
    </>
  )
}
